<template>
  <section id="home" class="pt-5">
    <v-card tile id="banner" class="py-3 mb-4">
      <v-row class="mx-2" justify="space-around">
        <v-col class="content" cols="12" lg="6">
          <div class="display-3 mb-4 font-weight-bold">
            Welcome to <br />
            <span class="purple--text accent-3">A1 Smoke Shop</span>.
          </div>
          <div class="mb-4">
            <v-chip-group column>
              <v-chip
                class="purple--text purple lighten-4"
                href="https://maps.google.com/?q=407 MacDade Boulevard, Folsom, PA 19033"
              >
                <span>
                  <v-icon class="purple--text darken-3 mr-2">mdi-home</v-icon>
                </span>
                407 MacDade Boulevard, Folsom, PA 19033
              </v-chip>
            </v-chip-group>
          </div>
        </v-col>
        <v-col class="d-none d-lg-block" cols="12" lg="6">
          <v-img
            :src="require('@/assets/home-bg.jpg')"
            width="500"
            height="500"
            alt="a1 smoke shop home banner"
            lazy-src="https://via.placeholder.com/300"
          ></v-img>
        </v-col>
      </v-row>
    </v-card>
    <v-row no-gutters class="py-5" id="new-products">
      <v-col cols="12" lg="3" md="6" v-for="(item, index) in items" :key="index">
        <product-card tag :item="item" />
      </v-col>
    </v-row>
        <v-row justify="space-between" class="py-5 text-center">
      <v-col cols="12">
        <v-card class="py-3 text-center" tile id="newsletter">
          <div class="py-3">
            <div class="display-1 font-weight-bold text-capitalize">
              Can't find what you need?
            </div>
          </div>
          <v-card-text>
            <span class="font-weight-medium mr-3 purple--text"
              >Sunday - Thursday</span
            >10AM–10PM<br />
            <span class="font-weight-medium mr-3 purple--text"
              >Friday - Saturday</span
            >10AM–11PM<br />
          </v-card-text>
          <v-row justify="center">
            <v-col>
              <v-btn rounded href="tel:4844943000" dark color="purple darken-3">
                <v-icon left> mdi-phone </v-icon>
                Call us Now
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="pb-5 mt-5" tile id="id-required">
      <v-row class="py-2 mx-3">
        <v-col>
          <v-img
            lazy-src="https://via.placeholder.com/400"
            max-width="600"
            alt="a1 smoke shop sample id"
            :src="require('@/assets/sample-id.png')"
          ></v-img>
        </v-col>
        <v-col>
          <v-card-title class="display-1 font-weight-bold">
            <span class="purple--text mr-3">ID</span>Required!</v-card-title
          >
          <v-card-text class="text--secondary"
            >Your ID will be required at your Pick-up! If your ID is not
            available, we will be forced to refund your order.</v-card-text
          >
        </v-col>
      </v-row>
    </v-card>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: { "product-card": ProductCard },
  computed: {
    ...mapGetters({ items: "trending" }),
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 100px;
}
</style>