<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        class="mx-auto"
        max-width="344"
        min-height="375"
        id="product-card"
        tile
      >
        <v-img :src="require(`@/assets/${item.imgs[0]}`)" :alt="'A1 Smoke Shop | '+item.title">
          <v-chip
            v-if="tag"
            pill
            class="
              mt-5
              ml-2
              text-capitalize
              font-weight-black
              purple
              white--text
            "
          >
            new
          </v-chip>
        </v-img>
        <v-card-text> A1 Smoke Shop | Folsom </v-card-text>
        <v-card-title class="text-h6 font-weight-black">{{
          item.title
        }}</v-card-title>
        <v-card-text class="purple--text text-capitalize">
          {{ item.type }}
        </v-card-text>
        <!-- <v-card-title>
          <v-rating
            :value="4"
            dense
            color="orange"
            background-color="orange"
            hover
            class="mr-2"
          ></v-rating>
          <span class="purple--text darken-3 text-subtitle-2">64 Reviews</span>
        </v-card-title> -->
        <v-fade-transition>
          <v-overlay v-if="hover" absolute color="purple lighten-3">
            <v-btn :to="`/products/${item.title}/${item.type}`">View</v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  name: "product-card",
  props: {
    tag: { type: Boolean, default: false },
    item: { type: Object },
  },
};
</script>

<style>
</style>